<template>
	<header class="header">

		<div class="header__logo">
			<img class="img-fluid" src="@/assets/img/main-logo_white.svg">
		</div>

		<div>
			<h1 class="h2 text-white mb-0">Werkomgeving inclusieve cultuur</h1>
			<h2 class="h5 text-white mb-0">{{ subtitle }}</h2>
		</div>
	</header>
</template>

<script>
export default {
	name: 'Home',
	computed: {
		subtitle() {
			switch (process.env.VUE_APP_SITE) {
				case 'diversiteit-scholen':
					return 'Voor het bevorderen van diversiteit in scholen';
			}
			
			return 'Voor het bevorderen van diversiteit in opleidingen';
		}
	}
}
</script>