<template>
	<div class="dashboard" v-if="page">
		<h2 class="text-secondary">{{ page.title }}</h2>

		<div class="row mb-5">
			<div class="col-12 col-lg-7 order-1 order-lg-0">
				<div class="cof-content">
					<div v-html="page.content"/>
				</div>
			</div>
			<div class="col-12 col-lg-5 order-0 order-lg-1">
				<div class="mb-4">
					<MediaEmbed :page="page" />
				</div>
				<div class="text-right">
					<div class="mb-3"><router-link to="/over/" class="btn btn-outline-secondary">Over de werkomgeving <span class="mr-0 ml-2"><icon icon="arrow-right"/></span></router-link></div>
				</div>
			</div>
		</div>

		<!-- Dashboard list -->
		<Themes @click-theme="chooseTheme" slug="analyse"/>
	</div>
</template>

<script>
    import api from '../data/api'
    import Themes from '@/components/Themes.vue';
	import MediaEmbed from "@/components/MediaEmbed";
    import Icon from "@/components/Icon";
    import {themeMixin} from "@/mixins/chooseThemeMixin";

    export default {
        name: 'Home',
        components: {Themes, MediaEmbed, Icon},
        mixins: [themeMixin],
        data() {
            return {
                pageTitle: 'Dashboard',
                page: null,
            }
        },
        mounted() {
            api.fetchPage(6).then(response => {
                this.page = response;
            });

            const breadcrumbs = [{
                'label': 'Home',
                'path': '/'
            }];
            this.$store.commit('SET_BREADCRUMBS', {breadcrumbs});
        },
    }
</script>
