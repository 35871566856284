<template>
	<div v-if="page.embedType === 'video' && page.videoEmbed" class="video-wrapper">
		<div class="embed-responsive embed-responsive-16by9" v-html="page.videoEmbed"></div>
	</div>
	<div v-else-if="page.embedType === 'image' && page.imageEmbed" class="page-image-wrapper">
		<img class="img-fluid" :src="page.imageEmbed.sizes.medium" :alt="page.imageEmbed.alt">
	</div>
</template>

<script>
export default {
	name: 'MediaEmbed',
	props: {
		page: Object,
	}
}
</script>